var escapeHtml = require('escape-html');
var _ = require('underscore');
var locale = require('locale');
const AppPromoManager = require('../appPromoManager');

var allLocales =
    require('lightning-common/lib/configuration/supportedLanguages.json').supportedLanguages;

var _htmlEscape = function (text, shouldEscapeHtml) {
    if (shouldEscapeHtml && text) {
        return escapeHtml(text);
    } else {
        return text;
    }
};

var _wrap = function (text, shouldWrap) {
    if (shouldWrap) {
        if (text == null) {
            return 'X (MISSING TEMPLATE) X';
        } else {
            return '✓ ' + text + ' ✓';
        }
    } else {
        return text;
    }
};

var _isHtmlTranslation = function (key) {
    return key && key.toLowerCase().endsWith('html');
};

var formatLocalizedText = function (key, text, options) {
    options = options || {};

    var shouldEscapeHtml = options.expectHtml && !_isHtmlTranslation(key);
    var shouldWrap = !!options.wrap;

    var possiblyHtmlEscaped = _htmlEscape(text, shouldEscapeHtml);
    var possiblyWrapped = _wrap(possiblyHtmlEscaped, shouldWrap);

    return possiblyWrapped || '';
};

var getLanguageHeaderLocale = function (req, libraryPrimaryLocale) {
    // Get all supported languages
    var supportedLanguages = new locale.Locales(
        _.flatten(_.pluck(allLocales, 'defaultLanguageCode')),
        libraryPrimaryLocale
    );
    const acceptLanguageHeader = req.headers['accept-language'];
    var locales = new locale.Locales(acceptLanguageHeader);
    var bestLanguage = locales.best(supportedLanguages).toString();

    if (bestLanguage === 'zh-Hans') {
        // LHTNG-4198 zh-Hans is returned as best even when zh-Hant is preferred & the library doesn't support zh-Hans
        var zhHantPreferred =
            locales.length > 0 &&
            (locales[0].code == 'zh-TW' ||
                locales[0].code == 'zh-HK' ||
                locales[0].code == 'zh-MO' ||
                locales[0].code == 'zh-Hant');

        if (zhHantPreferred && req.library && req.library.supportedLanguages) {
            var zhHansExists = false;

            req.library.supportedLanguages.forEach((language) => {
                if (language.defaultLanguageCode == 'zh-Hans') {
                    zhHansExists = true;
                }
            });

            if (!zhHansExists) {
                return 'zh-Hant';
            }
        }

        if (
            acceptLanguageHeader &&
            acceptLanguageHeader.toLowerCase().includes('zh') &&
            (libraryPrimaryLocale === 'zh-Hans' ||
                libraryPrimaryLocale === 'zh-Hant')
        ) {
            bestLanguage = libraryPrimaryLocale;
        }
    }

    return bestLanguage;
};

const parseUserAgentSystemInformation = function (userAgent) {
    const firstOpenParen = userAgent.indexOf('(');
    const firstCloseParen = userAgent.indexOf(')');
    if (firstOpenParen === -1 || firstCloseParen === -1) {
        return [];
    }
    const systemInfo = userAgent.substring(
        firstOpenParen + 1,
        firstCloseParen - 1
    );
    return systemInfo.split(';').map((info) => info.trim());
};

const getTolinoLocale = function (req) {
    // Tolino devices don't send over the correct accept-language header, but we can pull this value off of the system information.
    // The system information contains the language code followed by a hypen (e.g.: `de-`, `en-`, `es-`, etc)
    const userAgent = req.headers['user-agent'];
    const systemInfo = parseUserAgentSystemInformation(userAgent);
    const lang = systemInfo
        .filter((l) => l.endsWith('-'))
        .map((l) => l.substr(0, l.length - 1))[0];
    return lang || 'en';
};

var getLocale = function (req, res, libraryPrimaryLocale) {
    const appPromoManager = AppPromoManager.createServer(
        req,
        res,
        req.featureManager
    );

    if (req.query && req.query._locale) {
        return req.query._locale;
    } else if (
        req.profile &&
        req.profile.settings &&
        req.profile.settings['language-selection']
    ) {
        return req.profile.settings['language-selection'];
    } else if (req.cookies && req.cookies['language-selection']) {
        return req.cookies['language-selection'];
    } else if (appPromoManager.settings.isTolinoDevice) {
        return getTolinoLocale(req);
    } else if (req.headers['accept-language']) {
        return getLanguageHeaderLocale(req, libraryPrimaryLocale);
    } else if (libraryPrimaryLocale) {
        return libraryPrimaryLocale;
    } else {
        return 'en';
    }
};

// eslint-disable-next-line no-shadow
var getTranslationOptions = function (locale, expectHtml) {
    return {
        expectHtml: expectHtml,
        wrap: locale && locale.toLowerCase() === 'en-qa',
    };
};

// eslint-disable-next-line no-shadow
var isRtl = function (locale) {
    switch (locale.toLowerCase()) {
        case 'ar':
        case 'he':
        case 'en-rtl':
            return true;
        default:
            return false;
    }
};

module.exports = {
    formatLocalizedText,
    getLocale,
    getTranslationOptions,
    getLanguageHeaderLocale,
    isRtl,
};
