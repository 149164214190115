import { toStaticUrl } from '../../../../lib/assetMapper';

const bannerTitleImage = ({ src }) => `
<img role="presentation" src="${src}"/>
`;

export const kanopyMenuAdTemplate = ({
    kanopySignUpUrl,
    promoteSignUp,
    showTitles,
    titlesBottomRow,
    titlesTopRow,
}) => `
<section class="kanopy-menu-ad"
         onclick="event.stopImmediatePropagation()">
    <div class="kanopy-menu-ad__column-content">
        <h1 class="kanopy-menu-ad__heading">
            ${window.html('kanopy-menu-ad.header')}
        </h1>

        <p class="kanopy-menu-ad__content">
            ${window.html('kanopy-menu-ad.subheader')}
        </p>

        <p class="kanopy-menu-ad__content">
            <a class="kanopy-button--cta"
                href="${
                    promoteSignUp ? kanopySignUpUrl : window.routes.kanopy.promo
                }"
                id="kanopy-menu-ad-cta-button"
                rel="noreferrer"
                target="_blank"
                aria-label="${promoteSignUp
                        ? window.html('kanopy-menu-ad.getStartedWithKanopy')
                        : window.html('kanopy-menu-ad.learnMoreAboutKanopy')}">
                ${
                    promoteSignUp
                        ? window.html('kanopy-menu-ad.getStarted')
                        : window.html('kanopy-menu-ad.learnMore')
                }
            </a>
        </p>
    </div>

    <div class="kanopy-menu-ad__covers">
        ${
            showTitles
                ? `
        <div class="kanopy-menu-ad__cover-row">
            ${titlesTopRow.map((src) => bannerTitleImage({ src })).join('')}
        </div>
        <div class="kanopy-menu-ad__cover-row">
            ${titlesBottomRow.map((src) => bannerTitleImage({ src })).join('')}
        </div>
        `
                : ''
        }
    </div>
    
    <div class="kanopy-menu-ad__logo">
        <img alt="${html('kanopy.kanopy')}" 
            src="${toStaticUrl('img/kanopy/logo-kanopy-white.svg')}" />
    </div>
</section>
`;
