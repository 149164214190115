class Wishlist {
    constructor(bus) {
        this.bus = bus;
    }

    wishlistAction(titleModel, context) {
        let action =
            titleModel.get('wishlistState') === 'wishlistAdd'
                ? 'add'
                : 'remove';

        this.bus.trigger('title:wishlist:start', { titleModel, action });

        let request = new Promise((resolve, reject) => {
            if (titleModel.get('wishlistState') === 'wishlistAdd') {
                this._wishlistAddAjax(titleModel)
                    .then(resolve)
                    .fail((jqXHR) => reject({ jqXHR, titleModel }));
            } else {
                this._wishlistRemoveAjax(titleModel)
                    .then(resolve)
                    .fail((jqXHR) => reject({ jqXHR, titleModel }));
            }
        });

        request
            .then((...params) =>
                this.bus.trigger(
                    'title:wishlist:success',
                    { titleModel, action, context },
                    ...params
                )
            )
            .catch((...params) => {
                let { jqXHR } = ((params.length && params) || [{}])[0];

                this._wishlistFailure(titleModel, jqXHR);
                this.bus.trigger(
                    'title:wishlist:fail',
                    { titleModel, action, context },
                    ...params
                );
            })
            .then(() =>
                this.bus.trigger('title:wishlist:end', {
                    titleModel,
                    action,
                    context,
                })
            );

        return request;
    }

    _wishlistAddAjax(titleModel, context) {
        this.bus.trigger('title:wishlist:add:start', { titleModel, context });

        let response = $.ajax({
            url: window.url(window.routes.accountWishlistAdd, {
                mediaId: titleModel.id,
            }),
            type: 'PUT',
        });

        response
            .done(() => {
                titleModel.set('wishlistState', 'wishlistRemove');
                this.bus.trigger('title:wishlist:add:success', {
                    titleModel,
                    context,
                });
            })
            .fail((...params) =>
                this.bus.trigger(
                    'title:wishlist:add:fail',
                    { titleModel, context },
                    ...params
                )
            )
            .always(() =>
                this.bus.trigger('title:wishlist:add:end', {
                    titleModel,
                    context,
                })
            );

        return response;
    }

    _wishlistRemoveAjax(titleModel, context) {
        this.bus.trigger('title:wishlist:remove:start', {
            titleModel,
            context,
        });

        let response = $.ajax({
            url: window.url(window.routes.accountWishlistRemove, {
                mediaId: titleModel.id,
            }),
            type: 'DELETE',
        });

        response
            .done(() => {
                titleModel.set('wishlistState', 'wishlistAdd');
            })
            .fail((...params) =>
                this.bus.trigger(
                    'title:wishlist:remove:fail',
                    { titleModel, context },
                    ...params
                )
            )
            .always(() =>
                this.bus.trigger('title:wishlist:remove:end', {
                    titleModel,
                    context,
                })
            );

        return response;
    }

    _wishlistFailure(titleModel, jqXHR) {
        if (
            jqXHR.responseText &&
            JSON.parse(jqXHR.responseText).sessionExpired
        ) {
            this.bus.trigger('user:session:expired');
        } else {
            toaster.push(window.html('error.defaultErrorHtml'), 'error');
        }
    }
}

export default Wishlist;
