export default ({title, closeOnEsc, closeOnBlur, showCloseButton}) =>
`<div
    class="reveal-modal"
    aria-hidden="true"
    aria-label="${title}"
    role="dialog"
    data-reveal
    data-options="close_on_esc:${ closeOnEsc };close_on_background_click:${ closeOnBlur };"
>
    <div class="modal-header">
        <h2 class="modal-title primary-color">${ title }</h2>
        ${showCloseButton ? `<a class="close-reveal-modal js-close" role="button" aria-label="${ window.html('close') }" tabindex="0">&#215;</a>` : ''}
    </div>

    <div class="modal-container"></div>
</div>`;
