import BaseView from '../../../views/base';
import { surroundSubtext } from '../../../util/text';
import AutoCompletionTemplate from '../templates/autocompletion.html';

const Autocompletion = BaseView.extend({
    template: AutoCompletionTemplate,

    initialize({ model, query }) {
        this.model = model;
        this.query = query;
        this.listenTo(this.model, 'change:active', this.render);
    },

    deleteViewAndModel() {
        this.model.destroy();
        this.deleteView();
    },

    deleteView() {
        this.remove();
        this.unbind();
    },

    getTemplateData() {
        const href = window.url(window.routes.search, {
            query: this.model.get('text'),
        });

        return _.extend({}, this.model.attributes, {
            href,
            resultText: surroundSubtext({
                text: this.model.get('text'),
                matcher: this.query.get('text'),
                tag: 'b',
                ignoreCase: true,
            }),
            field: window.html(`autoComplete.${this.model.get('field')}`),
        });
    },
});

export default Autocompletion;
