var messageformat = require('./messageformat');
var utility = require('./utility');

/**
 * @param {Translations} translations - The translations configuration object
 * @param {Logger} logger - An optional object-logger
 */
function Translater(translations, logger, websiteId = 0) {
    // template text -> locale -> templateFunction
    // NOTE: We map using the template text to reduce cache key uniqueness errors
    // NOTE: We include the locale in the cache as the same text can be used for multiple locales;
    //       for example the following would cache the first locale to use it: {x, number}.
    this._compiledTemplates = {};

    // key (-> subkey)* -> (english template | locale -> template)
    this._translations = translations;

    // (level: string, message: string, args: string -> object): void
    this._logger = logger;

    this.websiteId = websiteId;
}

Translater.prototype.translate = function (
    key,
    requestedLocale,
    args,
    options,
    requestUrl
) {
    requestedLocale = requestedLocale || 'en';
    args = args || {};
    options = options || {};

    // Sould never be valid, but also guard against later code trying keys
    // that append strings onto nothing, which could be false positives.
    if (!key) {
        var keyDescription =
            key === ''
                ? '(empty string)'
                : key === null
                ? '(null)'
                : '(undefined)';
        this._log('error', 'Expected a non-empty key', {
            keyDescription,
            requestUrl,
        });

        return null;
    }

    var result = null;

    try {
        result = this._translate(
            key,
            requestedLocale,
            args,
            options,
            requestUrl
        );
    } catch (e) {
        // already logged; just gotta give them nothing for now
    }

    if (result) {
        // result should already be formatted
        return result;
    } else {
        // still gotta format nothing for all the logic to work out
        return utility.formatLocalizedText(key, result, options);
    }
};

Translater.prototype._translate = function (
    requestKey,
    requestedLocale,
    args,
    options,
    requestUrl
) {
    const keysToTry = this._getKeysToTry(requestKey);

    for (const key of keysToTry) {
        const translation = this._tryGetTranslation(key);

        if (translation === null) {
            continue;
        }

        const templateFunction = this._compileTemplate(
            key,
            requestedLocale,
            translation,
            requestUrl
        );

        if (templateFunction === null) {
            continue;
        }

        const translated = templateFunction(args);
        const result = utility.formatLocalizedText(key, translated, options);

        return result;
    }

    // no keys could produce a result
    this._log(
        'error',
        'Could not find a translation for any of the following keys',
        {
            websiteId: this.websiteId,
            keysToTry,
            key: requestKey,
            locale: requestedLocale,
            args,
            requestUrl,
        }
    );

    return null;
};

Translater.prototype._compileTemplate = function (
    key,
    requestedLocale,
    translation,
    requestUrl
) {
    var localesToTry = this._getLocalesToTry(requestedLocale);

    for (var localeToTry of localesToTry) {
        var template = translation[localeToTry];

        if (template != null) {
            if (this._compiledTemplates[template] == null) {
                this._compiledTemplates[template] = {};
            }

            if (this._compiledTemplates[template][localeToTry] == null) {
                this._compiledTemplates[template][localeToTry] =
                    messageformat.compileTemplate(template, localeToTry);
            }

            return this._compiledTemplates[template][localeToTry];
        }
    }

    this._log(
        'error',
        'Found a translation but no template for any of the following locales',
        {
            translation,
            localesToTry,
            rawLocale: requestedLocale,
            key,
            requestUrl,
        }
    );

    return null;
};

Translater.prototype._tryGetTranslation = function (key) {
    var parts = key.split('.');
    var cursor = this._translations;
    for (var part of parts) {
        cursor = cursor[part];

        if (cursor == null) {
            return null;
        }
    }

    var translation = cursor;

    // If the translation mapping is just a string, treat it as a mapping
    // of english to that string.
    if (typeof translation === 'string') {
        translation = { en: translation };
    }

    return translation;
};

Translater.prototype._getLocalesToTry = function (requestedLocale) {
    if (requestedLocale && requestedLocale !== 'en') {
        var dashIndex = requestedLocale.indexOf('-');

        if (dashIndex === -1) {
            return [requestedLocale, 'en'];
        } else {
            var language = requestedLocale.substr(0, dashIndex);

            if (language.toLowerCase() === 'en') {
                return [requestedLocale, 'en'];
            } else {
                return [requestedLocale, language, 'en'];
            }
        }
    } else {
        return ['en'];
    }
};

Translater.prototype._getKeysToTry = function (key) {
    return [key + 'Html', key];
};

Translater.prototype._log = function (level, message, args) {
    if (this._logger) {
        this._logger(level, message, args);
    }
};

module.exports = Translater;
