import 'es5-shim';
import 'babel-polyfill';

import _onlyForBootstrapping from '../global_bootstrapping.js';
import _onlyForBindings from '../global_bindings.js';

import Logger from '../util/logging/logger';
import EventBridge from '../components/site/EventBridge';
import Card from '../models/Card';
import GlobalPreferences from '../models/GlobalPreferences';

window.Logger = Logger;

void (_onlyForBootstrapping, _onlyForBindings);

window.globalPreferences = new GlobalPreferences(
    {},
    { bus: window.globalEvents }
);
window.card = new Card();

void new EventBridge({
    bus: window.globalEvents,
    card: window.card,
    patronSettings: window.patronSettings,
    featureManager: window.featureManager,
    globalPreferences: window.globalPreferences,
});
