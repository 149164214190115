import createComponentQuickSearchComponent from './components/quick_search/main.js';
import createHoldsRatioModalComponent from './components/holdInformation/main.js';
import createMobileAccountMenuComponent from './components/mobile_account_menu/main.js';
import expensiveDomTraversalForDyslexicIfCookiePleaseDoNotUse from './util/dyslexic/expensiveDomTraversalForDyslexicIfCookiePleaseDoNotUse.js';
import { removeCarouselFunctionForOldAndroid } from './util/compatibility/removeCarouselFunctionality.js';
import HistoryEventTracker from './components/history_event_tracker/HistoryEventTracker';
import svgLoader from './util/svgLoader';
import NotificationDrawer from './components/notifications_drawer/NotificationsDrawer';
import keys from '../ecmascript/util/keycodes';
import TlsWarningModal from './components/tls-warning-modal/tlsWarningModal.js';

/*
 * A function full of functions to be run on document ready
 */
const onDocumentReady = function () {
    if (!window.OverDrive.isMinimalLayout) {
        // Create and attach holds ratio help modal
        createHoldsRatioModalComponent({
            el: document.querySelector('body'),
        });

        // Create and attach desktop autocomplete component
        createComponentQuickSearchComponent({
            searchEl: document.querySelector('.js-desktopSearchContainer'),
            autocompletionsEl: document.querySelector(
                '.js-quickSearchAutocompletionsDesktop'
            ),
            toggleSearchEl: $('.desktop .js-toggleSearch'),
            options: { desktop: true },
        });

        // Create and attach mobile autocomplete component
        createComponentQuickSearchComponent({
            searchEl: document.querySelector('.js-mobileSearchContainer'),
            autocompletionsEl: document.querySelector(
                '.js-quickSearchAutocompletionsMobile'
            ),
            toggleSearchEl: null,
            options: { mobile: true },
        });

        createMobileAccountMenuComponent({
            el: document.querySelector('.nav-container'),
        });

        // Do expensive traversal for dyslexic if cookie set
        expensiveDomTraversalForDyslexicIfCookiePleaseDoNotUse();

        // Remove carousel functionality on old androids
        removeCarouselFunctionForOldAndroid(bowser);

        // new history tracker using cookies for auto scroll and back button redirect
        new HistoryEventTracker();

        svgLoader();

        if (
            window.featureManager.isEnabled('Notifications') &&
            window.OverDrive.isAuthenticated
        ) {
            // We don't want to attach this to both the mobile and desktop nav targets simultaneously.
            window.notificationDrawer = new NotificationDrawer();
        }
    }

    if (
        toaster &&
        window.OverDrive.insufficientTls &&
        window.featureManager.isEnabled('show-tls-version-warning') &&
        !Cookies.get('tls-warning')
    ) {
        var link = `<a href="https://help.overdrive.com/en-us/2271.htm" target="_blank" rel="noreferrer">${window.html(
            'tlsWarning.learnMore'
        )} ></a>`;
        toaster.push(
            `${window.html('tlsWarning.noAccess')} ${link}`,
            'error',
            'tls-warning'
        );
    }

    if (window.OverDrive.showTlsWarningModal) {
        new TlsWarningModal();
    }

    // Close foundation dropdowns with ESC and return focus to parent
    $(document).keyup(function (e) {
        if (
            window.featureManager.isEnabled('close-dropdowns-on-esc') &&
            e.which === keys.ESC &&
            $('.f-open-dropdown').length > 0
        ) {
            var id = $('.f-open-dropdown').attr('id');
            $(document).foundation('dropdown', 'closeall');
            $(`[data-dropdown=${id}]`).focus();
        }
    });
};

export default onDocumentReady;
