import BaseView from '../../views/base.js';
import { standardToast } from './toaster.html';
import ToasterTemplate from './templates/toaster.html';

const APPLE_APP_STORE_HREF =
    'https://itunes.apple.com/us/app/libby-by-overdrive-labs/id1076402606?pt=211483&ct=meetLibby&mt=8&utm_medium=pop_up&utm_source=overdrive_app&utm_campaign=switch_to_libby&utm_content=odapp_popup_apple_store';
const GOOGLE_PLAY_STORE_HREF =
    'https://play.google.com/store/apps/details?id=com.overdrive.mobile.android.libby&referrer=utm_source%3Dmeet_libby&utm_medium=pop_up&utm_source=overdrive_app&utm_campaign=switch_to_libby&utm_content=odapp_popup_google_play';

const Toaster = BaseView.extend({
    className: 'Toaster js-toasterContent',

    timeout: null,

    line: null,

    loaf: [],

    staleLoaf: [],

    currentToast: {},

    hooks: {
        timer: '.js-toastTimer',
        message: '.js-toastMessage',
        toast: '.js-toast',
        close: '.js-closeToast',
    },

    toasterTypes: {
        message: '',
        system: 'is-system',
        error: 'is-burnt',
        userAction: 'is-userAction',
    },

    template: ToasterTemplate,

    events() {
        return {
            [`click ${this.hooks.close}`]: 'closeToast',
        };
    },

    initialize({ target }) {
        this.target = target;
    },

    closeToast() {
        const $element = this.$(this.hooks.message);
        const breadId = $element.attr('id');

        const keys = {
            'auto-checkout-toast': { name: 'auto-checkout-toast', value: '1' },
        };
        const value = keys[breadId];
        if (value) {
            Cookies.remove(value.name, { path: '/' });
            Cookies.set(value.name, value.value, {
                path: '/',
                expires: 365,
                sameSite: 'lax',
                secure: true,
            });
        }

        if (breadId === 'system-toast') {
            const messageId = parseInt($element.attr('data-message-id'), 10);
            const cookie = Cookies.get('system-messaging');
            let newCookie = {};

            if (cookie) {
                const cookieJSON = JSON.parse(cookie);

                // If the cookie has an attribute, the cookie is valid JSON
                if (cookieJSON.oldest || cookieJSON.newest) {
                    newCookie = {
                        oldest: cookieJSON.oldest,
                        newest: cookieJSON.newest,
                    };

                    if (parseInt(cookieJSON.oldest, 10) > messageId) {
                        newCookie.oldest = messageId;
                    }

                    if (parseInt(cookieJSON.newest, 10) < messageId) {
                        newCookie.newest = messageId;
                    }
                } else {
                    // User has old cookie value that was only an integer
                    // Setting this way to get the form of the proper cookie
                    newCookie = {
                        oldest: messageId,
                        newest: messageId,
                    };
                }
            } else {
                // User does not have system-messaging cookie set
                newCookie = {
                    oldest: messageId,
                    newest: messageId,
                };
            }

            Cookies.remove('system-messaging', { path: '/' });
            Cookies.set('system-messaging', newCookie, {
                path: '/',
                expires: 365,
                sameSite: 'lax',
                secure: true,
            });
        }

        if (breadId === 'tls-warning') {
            Cookies.remove('tls-warning', { path: '/' });
            Cookies.set('tls-warning', 'dismissed', {
                path: '/',
                expires: 14,
                sameSite: 'lax',
                secure: true,
            });
        }

        this.pop();
        // If there was a toast displaying already when the just closed toast was pushed, display that toast
        if (this.staleLoaf.length > 0) {
            this.loaf.push(this.staleLoaf.pop());
        }
        this.currentToast = {};
        this.showNextToast();
    },

    /*
     * Ensures newly generated toast is visible.
     */
    ensureVisibility() {
        this.ensureOnTop();
        this.ensureInViewport();
        this.setPlaceholderHeight();
    },

    /*
     * Show newly popped toast on top of modal background
     */
    ensureOnTop() {
        if ($('.reveal-modal.open').hasClass('HoldSuccessModal')) {
            this.$el.css({
                'z-index': '99999',
                '-webkit-transform': 'translate3d(0,0,1px)',
                transform: 'translate3d(0,0,1px)',
                position: 'fixed',
                top: '0!important',
            });
        } else {
            this.$el.css({
                position: 'inherit',
            });
        }
    },

    /*
     * Automatically scroll to top of page on earlier android devices.
     * (They do not support position:fixed well).
     */
    ensureInViewport() {
        if (bowser && bowser.android) {
            const androidVersion = parseInt(bowser.osversion, 10);
            if (androidVersion < 3) {
                $(window).scrollTop(0);
            }
        }
    },

    // Set height of toast placeholder so the page doesn't jump
    setPlaceholderHeight() {
        $('.js-toastPlaceholder').css('height', this.$el.height());
    },

    /*
     * Generate a success toast message
     * @param {string} bread The message to place in the toast
     * @param {string} toasterType Type of toast
     * @param {string} id Id of the toast
     * @param {int} messageId Message id of system toasts. Needed only for system toasts
     */
    push(message, toasterType = 'message', id = '', messageId = 0) {
        if (message && message !== '') {
            this.loaf.push({ message, toasterType, id, messageId });
        }
        this.showNextToast();
    },

    /*
     * Push array of system messages to loaf
     * @param msgArr Array of system messages
     */
    bulkSystemMsgPush(msgArr) {
        let messages = msgArr;

        this.loaf = this.loaf.concat(messages);
        this.showNextToast();
    },

    /*
     * Show next message in loaf
     */
    showNextToast() {
        if (this.loaf.length > 0) {
            const nextToast = this.loaf.pop();
            nextToast.toasterType =
                this.toasterTypes[nextToast.toasterType] ||
                nextToast.toasterType;
            // Do not stack user action (borrow, hold, wishlist) toasts
            if (
                !_.isEmpty(this.currentToast) &&
                this.currentToast.toasterType !== this.toasterTypes.userAction
            ) {
                this.staleLoaf.push(this.currentToast);
            }
            this.generateToast(nextToast);
            this.currentToast = nextToast;
            this.ensureVisibility();
        } else {
            // Stop showing toast placeholder
            $('.js-toastPlaceholder').hide();
            $(window).unbind('scroll.toast');
        }
    },

    /*
     * Generate toast UI
     * @param data Toast object
     */
    generateToast(data) {
        if (
            featureManager.isEnabled('Notifications') &&
            window.notificationDrawer &&
            window.notificationDrawer.isOpen
        ) {
            window.notificationDrawer.close();
        }

        const defaultTemplateData = {
            appleAppStoreHref: APPLE_APP_STORE_HREF,
            googlePlayStoreHref: GOOGLE_PLAY_STORE_HREF,
            highContrast: window.OverDrive.highContrast,
            hooks: this.hooks,
            messageId: 0,
            toasterType: this.toasterTypes.message,
        };
        const templateData = _.extend({}, defaultTemplateData, data);
        this.pop();

        this.template = standardToast;

        this.render({ templateData });

        this._stickToast();
        $(window).on('orientationchange', () => {
            this._stickToast();
        });
        $(window).on('resize', () => {
            this._stickToast();
        });

        this.$(this.hooks.message).focus();
    },

    /*
     * Remove the currently displayed toast message
     */
    pop() {
        const toast = this.$(this.hooks.toast);
        toast.removeClass('animated fadeIn').addClass('animated fadeOut');
        toast.one(
            'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend',
            toast.remove()
        );
    },

    /*
     * Make the toast message sticky
     * @private
     */
    _stickToast() {
        const width = $(window).width();
        const scrollTop =
            $(window).scrollTop() - $('.js-toaster').outerHeight(true);
        const toast = this.$el;
        const skipHeight = $('div.skip-links').is(':visible')
            ? $('div.skip-links').outerHeight(true)
            : 0;
        const mobileNavHeight = $('.js-mobileNav').outerHeight(true);
        const navMenuHeight = $('.js-nav').is(':visible')
            ? $('.js-nav').outerHeight(true)
            : 0;
        const expandedSearchHeight = $('.js-nav-search-drop').is(':visible')
            ? $('.js-nav-search-drop').outerHeight(true)
            : 0;
        let navHeight = 0;

        // Mobile toaster logic
        if (width <= 870) {
            navHeight = navMenuHeight + mobileNavHeight + skipHeight;
        } else if (width > 870) {
            // Desktop toaster logic
            navHeight = navMenuHeight + expandedSearchHeight + skipHeight;
        }

        // If the hold success modal is open, take special action with toast
        // Otherwise, handle toast as normal
        if ($('.reveal-modal.open').hasClass('js-holdSuccessModal')) {
            // Sets position to fixed and top to 0
            toast.addClass('sticky');
        } else {
            this.$el.css({
                position: 'inherit',
                'z-index': '998',
            });

            toast.css('top', navHeight);
            if (scrollTop >= navHeight) {
                toast.addClass('sticky');
                $('.js-toastPlaceholder').show();
            } else {
                toast.removeClass('sticky');
                $('.js-toastPlaceholder').hide();
            }

            $(window).on('scroll.toast', () => {
                toast.toggleClass('sticky', $(window).scrollTop() > navHeight);
                if (toast.hasClass('sticky')) {
                    $('.js-toastPlaceholder').show();
                } else {
                    $('.js-toastPlaceholder').hide();
                }
            });
        }
    },
});

export default new Toaster({ target: '.js-toaster' });
