import { Modal } from '../common/modal';

export default class ConfirmationModal extends Modal {
    constructor({ copyToUse, callback, triggerElement }) {
        super({
            content: `
                <p>${copyToUse}</p>
                <div class="button-container"></div>`,
            classes: ['ConfirmationModal', 'small'],
            title: copyToUse,
            hiddenTitle: true,
            showCloseButton: true,
        });

        const buttonAction = () => {
            if (callback) {
                callback();
            } else {
                this.close();
            }
            if (triggerElement) {
                triggerElement.focus();
            }
        };

        const closeButton = $(`
            <button class="button margin-small radius secondary u-allCaps">
                ${html('ok')}
            </button>`).on('click keydown', (e) => {
            if (e.type === 'click' || e.key === 'Enter') {
                e.stopPropagation();
                requestAnimationFrame(buttonAction);
            }
        });

        if (callback) {
            this.on('close cancel', () => callback());
        }

        this.$content.find('.button-container').append(closeButton);
        this.show();
        this.$el.find('.close-reveal-modal').focus();
    }
}
