import ProcessForwardedAction from '../../util/title-actions/processForwardedAction';
import HashManager from '../../components/common/HashManager';

import TitleCard from './TitleCard';
import TitleActions from '../../title-actions/TitleActions';
const he = require('he');
// This assumes that the calling code passes a Backbone.Collection with a model type of Title
export default class TitleCollectionView extends Backbone.View {
    constructor({
        bus,
        patronMediaItemsModel,
        View,
        attributes,
        coverWidths,
        isInHero,
        context,
    }) {
        super(...arguments);

        this._views = [];

        this.bus = bus;
        this.View = View || TitleCard;
        this.attributes = _.extend({}, attributes);
        this.coverWidths = coverWidths;
        this.isInHero = isInHero;
        this.context = context;

        this.titleActions = new TitleActions({ bus: this.bus, context });

        this.listenTo(this.collection, 'title:action', this._handleTitleAction);

        // TODO: >>> Remove this call and _updateMediaStates() logic and update dependant consumers to use PatronTitleStatus
        // Fetch patron data and trigger state updates.
        // Only fetch patron data if authed and has linked cards.
        if (
            patronMediaItemsModel &&
            window.OverDrive.isAuthenticated &&
            window.OverDrive.hasLinkedCards
        ) {
            patronMediaItemsModel.initialFetch().then((patronData) => {
                this._updateMediaStates(patronData);
            });
        }

        this.render();
    }
    get tagName() {
        return 'ul';
    }

    _handleTitleAction({ action, model }) {
        this.titleActions.perform(action, model);
    }

    _updateMediaStates(patronData) {
        let ids = [];
        _(_.values(patronData)).each((item) => {
            if (_.isArray(item)) {
                ids.push(...item);
            } else if (_.isObject(item)) {
                ids.push(..._.keys(item).map(Number));
            } else {
                ids.push(item);
            }
        });
        ids = _.uniq(ids);

        ids.forEach((id) => {
            const model = this.collection.get(id);

            if (model) {
                model.updatePatronState(patronData);
            }
        });

        this._performForwardedAction();
    }

    render() {
        let frag = document.createDocumentFragment();

        this._views.forEach((view) => view.remove());
        this._views = [];

        this.collection.forEach((title) => {
            title.set('title', he.decode(title.get('title')));

            title.setContext(this.context);

            let view = new this.View({
                model: title,
                bus: this.bus,
                coverWidths: this.coverWidths,
                isInHero: this.isInHero,
            });

            frag.appendChild(
                $('<li />', {
                    class: 'js-titleCard Item',
                }).append(view.$el)[0]
            );

            this._views.push(view);
        });

        this.$el.attr(this.attributes);
        this.$el.empty().append(frag);
    }

    remove() {
        this._views.forEach((view) => view.remove());
        super.remove();
    }

    _performForwardedAction() {
        if (
            window.OverDrive.isAuthenticated &&
            window.OverDrive.hasLinkedCards &&
            HashManager.get('action')
        ) {
            const forwardedAction = new ProcessForwardedAction(
                this.collection,
                this.bus
            );
            forwardedAction.executeAction();
            window.forwardedAction = forwardedAction;
        }
    }
}
