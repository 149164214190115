import { KanopyMenuAdView } from '../../ecmascript/components/kanopy-menu-ad/kanopy-menu-ad-view';

/* eslint no-invalid-this: "off" */
/* eslint func-style: "off" */
var openMenu = 'main';

var trimOutsideForwardSlashes = function (s) {
    if (s) {
        return s.replace(/^\/?(.*?)\/?$/, '$1');
    } else {
        return s;
    }
};

var getFormWidth = function ($elem) {
    return (
        $('.js-mobileSearchContainer').width() -
        $('.js-mobileNavSearchIcon').width() -
        $elem.width() -
        30
    );
};

const renderKanopyAd = () => {
    const menuAdEl = $('.kanopy-menu-ad-container');
    if (window.featureManager.isEnabled('kanopy-promo') && menuAdEl) {
        new KanopyMenuAdView({
            el: menuAdEl,
            highContrast: window.OverDrive.highContrast,
            isTolinoDevice: window.OverDrive.isTolinoDevice,
            kanopySignUpUrl: window.OverDrive.kanopySignUpUrl,
        });
    }
};

$(document).ready(function () {
    // Don't submit a blank search
    $(document).on('keyup', 'input[data-search]', function (e) {
        if (e.which == 13) {
            if ($(this).val() != '') {
                $(this).parents('form').submit();
            } else {
                e.preventDefault();
                return false;
            }
        }
    });

    // if the user is not on the home page...
    var currentPath = trimOutsideForwardSlashes(location.pathname);
    var homePath = trimOutsideForwardSlashes(window.url(window.routes.home));
    if (currentPath !== homePath) {
        // ...then underline the nav link of the current page if it exists
        // TODO: Refactor away these general selectors and do it more cleanly
        $('a[href*="' + currentPath + '"]').addClass('selected');
        $('a[href*="' + currentPath + '"] li span').addClass('selected');
    }

    if ($(document).width() > 870) {
        $('.js-toggleRow').removeClass('row');
    }

    // Fit mobile search to container
    $('.js-mobileSearchForm').css(
        'width',
        getFormWidth($('.js-mobileNavIconContainer')) + 'px'
    );
});

$('.js-mobileSearchInput')
    .on('touchstart click', function () {
        setTimeout(function () {
            if (!$('.js-advancedSearchContainer').is(':visible')) {
                $('.js-advancedSearchContainer').toggle();
                if (window.toaster) window.toaster._stickToast();
            }

            if ($('.js-account-menu-mobile').hasClass('is-open')) {
                window.AccountMenuMobileView.close();
                if (window.toaster) window.toaster._stickToast();
            }
        }, 1);
    })
    .on('focusout', function () {
        setTimeout(function () {
            var focused = $(document.activeElement);
            if (
                !focused.hasClass('js-advancedSearchLink') &&
                $('.js-advancedSearchContainer').css('display') == 'block' &&
                !focused.hasClass('js-mobileSearchInput')
            ) {
                if (window.toaster) window.toaster._stickToast();
            }
        }, 1);
    });

$(
    '.js-notificationsButton, .MobileNav-bookshelfLink, .js-mobileMenu, .js-advancedSearchContainer, .js-mobileSearchInput'
).on('focusout', function () {
    setTimeout(function () {
        var focused = $(document.activeElement);
        if (
            !focused.hasClass('js-notificationsButton') &&
            !focused.hasClass('MobileNav-bookshelfLink') &&
            !focused.hasClass('js-mobileMenu') &&
            !focused.hasClass('js-advancedSearchLink') &&
            !focused.hasClass('js-mobileSearchInput')
        ) {
            $('.js-advancedSearchContainer').hide();
        }
    }, 1);
});

// Handle mobile nav menu
$('.js-mobileMenu').on(
    'tap click',
    _.debounce(function (e) {
        e.preventDefault();
        e.stopPropagation();

        // LHTNG-3938 - this is a last ditch hack.  The notification drawer uses a click handler on <body> to close itself, but
        // this menu doesn't play nice with that pattern. I tried to clean that up and allow the click to propagate but that
        // revealed a huge mess of issues that we can't tackle now.
        if (
            window.featureManager.isEnabled('Notifications') &&
            window.notificationDrawer
        ) {
            window.notificationDrawer.close();
        }

        renderKanopyAd();

        setTimeout(function () {
            if (window.toaster) window.toaster._stickToast();
        }, 0);

        if ($('.icon-search-nav').hasClass('expanded')) {
            $('.icon-search-nav')
                .removeClass('expanded')
                .addClass('unexpanded');
        }

        $(
            '#mobile-nav-foundation-dropdown[data-menu="' + openMenu + '"]'
        ).toggle();

        if ($('.js-account-menu-mobile').hasClass('is-open')) {
            window.AccountMenuMobileView.close();
        }

        if ($(this).attr('id') == 'hamburger') {
            if ($(this).attr('aria-expanded') == 'false') {
                $(this).attr('aria-expanded', 'true');
            } else {
                $(this).attr('aria-expanded', 'false');
            }
        }
        return false;
    }, 350)
);

// Advantage tout
$('div#dropAccount.large')
    .on('opened.fndtn.dropdown', function () {
        $('.top-bar').css('overflow', 'visible');
        var link = $(this).find('a');
        $(this).find('p').attr('tabindex', link.attr('data-tab'));
        link.attr('tabindex', link.attr('data-tab'));
    })
    .on('closed.fndtn.dropdown', function () {
        $(this).find('p').attr('tabindex', -1);
        $(this).find('a').attr('tabindex', -1);
    });

$(window).resize(function () {
    var width = $(document).width();
    if (width <= 855) {
        $('.js-toggleRow').addClass('row');

        var searchForm = $('.js-mobileSearchForm');
        if (searchForm.hasClass('is-expanded')) {
            searchForm.css(
                'width',
                getFormWidth($('.js-searchControlContainer')) + 'px'
            );
        } else {
            searchForm.css(
                'width',
                getFormWidth($('.js-mobileNavIconContainer')) + 'px'
            );
        }
    } else {
        $('.js-toggleRow').removeClass('row');
    }
});

function exitSearchMode() {
    $('.js-searchControlContainer').addClass('u-hideMe');
    var iconContainer = $('.js-mobileNavIconContainer');
    iconContainer.removeClass('u-hideMe');
    $('.js-mobileSearchForm')
        .removeClass('is-expanded')
        .addClass('is-unexpanded')
        .css('width', getFormWidth(iconContainer) + 'px');
}

function enterSearchMode() {
    var searchContainer = $('.js-searchControlContainer');
    searchContainer.removeClass('u-hideMe');
    $('.js-mobileNavIconContainer').addClass('u-hideMe');
    $('.js-mobileSearchForm')
        .addClass('is-expanded')
        .removeClass('is-unexpanded')
        .css('width', getFormWidth(searchContainer) + 'px');
}

// Close mobile search bar
$('.js-clearMobileSearch').on('tap click', function () {
    $('.js-mobileSearchInput').val('').trigger('keyup').focus();
    exitSearchMode();
    $('.js-advancedSearchContainer').show();

    //Hacks until I move all this logic to backbone views
    $('.js-quickSearchInput', '.js-quickSearchMobile').trigger('keyup');
    $('.js-quickSearchInput', '.js-quickSearchMobile').trigger('focusout');
});

$('.js-mobileSearchInput')
    .on('keyup', function () {
        enterSearchMode();

        if ($(this).val().length === 0) {
            exitSearchMode();
            $('.js-advancedSearchContainer').show();
        }
    })
    .on('click focus', function () {
        if ($(this).val().length > 0) {
            enterSearchMode();
        }
        $('.js-advancedSearchContainer').show();
    })
    .on('blur', function () {
        // When mobile search input loses focus, unhide mobile account menu
        $('.js-account-menu-link, .js-account-menu-mobile').removeClass(
            'hideMeImportant'
        );
    });

$(document).click(function (e) {
    if (!$(e.target).parent().parent().hasClass('js-mobileSearchContainer')) {
        exitSearchMode();
    }
});

$('.js-doQuickSearch').on('touchstart click', function (e) {
    if ($('.js-mobileSearchInput').val() !== '') {
        $('#search-form-mobile').submit();
    }
});

//Toggle foundation accordion
$('.accordion.top-nav li, span.nav-panel-icon').on(
    'tap click',
    'a:eq(0), a:eq(1), span',
    function (e) {
        e.stopPropagation();

        var li_parent = $(this).parent();

        if (li_parent.hasClass('active') && li_parent.hasClass('top-nav')) {
            li_parent.removeClass('active');
            $('.accordion.top-nav li div.content:visible').toggle();
        } else if (li_parent.hasClass('top-nav')) {
            $('.accordion-navigation.top-nav').removeClass('active');
            li_parent.addClass('active');
            $('.accordion.top-nav li div.content:visible').toggle();
            $(this).parent().find('.content').toggle();
        }

        if (window.toaster) window.toaster._stickToast();
    }
);

// Remove desktop view searchbar autofocus on mobile devices
var mobile = bowser.mobile;
var tablet = bowser.tablet;

if (mobile || tablet) {
    $('.search-input').removeAttr('autofocus');
} else {
    $('.search-input').focus();
}

// TODO: Evaluate if this is still needed - MD
window.onorientationchange = function () {
    if (
        $('.search-input-mobile').css('display') != 'none' &&
        $('.search-input').css('display') == 'none' &&
        $('input:focus').length > 0 &&
        Math.abs(window.orientation) != 90
    ) {
        $('.js-mobileSearchInput').trigger('click');
    } else if (
        Math.abs(window.orientation) === 90 &&
        $('input:focus').length > 0
    ) {
        $('.search-input').trigger('click');
    } else if ($('input:focus').length > 0) {
        $('.js-mobileSearchInput').trigger('click');
    }
};

$('input[data-search]').click(function (e) {
    if (!$('input[data-search]').is(':focus')) {
        document.activeElement.blur();
        $(this).blur();
        var temp = $(this).val();
        $('input[data-search]').val(temp);
        $(this).focus();
        e.preventDefault();
    }
});

if ($('.js-mobileLibraryLogo').attr('src') == '') {
    showAltLogoMobile();
}

if ($('h1#logo-desktop a img').attr('src') == '') {
    showAltLogoDesktop();
}

/**
 * Set tab indices for nav menu
 */

var resized = false;
$('ul#drop-collections')
    .on('opened.fndtn.dropdown', function (event) {
        $(this).find('a, h2').attr('tabindex', 0);
        // $(this).find('h2:first').focus();
        if (!resized) {
            // Determine size of navigation based on # of columns
            var nav = $('ul#drop-collections');
            var columns = nav.find('ul:first-child').children('li');
            var columnCount = columns.length;
            var columnWidth = (1 / columnCount) * 100 + '%';

            columns.css('width', columnWidth);

            if (columnCount === 3) {
                columns.last().css('width', '200px');
            }

            nav.addClass('nav-' + columnCount + '-columns');
            resized = true;
        }
    })
    .on('closed.fndtn.dropdown', function () {
        $(this).find('a, h2').attr('tabindex', -1);
    });

$('.Nav-collectionsLink').on('click', function (event) {
    event.stopPropagation();
    event.preventDefault();
    window.Foundation.libs.dropdown.toggle($(this));

    renderKanopyAd();
});

$('.Nav-collectionsLink').on('keyup', function (event) {
    event.stopPropagation();
    event.preventDefault();
    if (event.which === 13) {
        window.Foundation.libs.dropdown.toggle($(this));

        renderKanopyAd();
    }
});

// Close nav when account dropdown is opened
$('#dropAccount').on('opened.fndtn.dropdown', function (e) {
    window.Foundation.libs.dropdown.close($('#drop-collections'));
});

$('.js-closeAdvantageTout').on('click tap', function () {
    Cookies.set('advantage-dismissed', '1', {
        path: '/',
        expires: 1,
        sameSite: 'lax',
        secure: true,
    });

    var accountSignInUrl = window.url(window.routes.accountSignIn);

    $('.js-advantageTout').hide();
    if (window.toaster) window.toaster._stickToast();
    $('a#account-holder-name, [data-dropdown="dropAccountMobile"]')
        .removeAttr('aria-haspopup data-options aria-controls')
        .attr({ href: accountSignInUrl, 'data-forward': '' });
    $(
        '#account-holder-name, li.account-dropdown-mobile a i.icon-account, [data-dropdown="dropAccountMobile"]'
    ).on('click', function (e) {
        e.stopPropagation();
    });
});
