import HoldModal from '../../components/media/views/HoldModal';
import HoldSuccessModal from '../../components/media/views/HoldSuccessModal';
import VisitorActionDisallowedModal from '../../components/visitor_action_disallowed_modal/VisitorActionDisallowedModal';
import ConfirmationModal from '../../components/confirmation_modal/ConfirmationModal';

export default class HoldActions {
    constructor(bus) {
        this.bus = bus;

        this.bus
            .on('title:hold:success', (data) => this.holdSuccess(data))
            .on('title:hold:fail', (data) => this.holdFail(data))
            .on('title:hold:request-email', (data) => this._openHoldModal(data))
            .on('title:hold:conflict', (data) =>
                this._openConflictModal(data.titleModel)
            )
            .on('title:hold-cancel:fail', (data) => this.holdCancelFail(data))
            .on('hold:suspend:completed', (data) =>
                this.holdSuspendSuccess(data)
            )
            .on('hold:emailChange:completed', (data) =>
                this.holdEmailChangeSuccess(data)
            )
            .on('title:suspend:cancelled', (data) =>
                this.removeHoldSuspension(data.titleModel)
            )
            .on('title:hold-cancel:success', (data) =>
                this.holdCancelSuccess(data.titleModel)
            );

        this.featureManager = window.featureManager;
    }

    removeHoldSuspension(titleModel) {
        const modalOptions = {
            copyToUse: window.text(
                'confirmationModal.removeHoldSuspensionSuccess',
                {
                    title: titleModel.get('title'),
                }
            ),
            callback: () => window.location.reload(),
        };
        new ConfirmationModal(modalOptions);
    }

    holdCancelSuccess(titleModel) {
        const modalOptions = {
            copyToUse: window.text('confirmationModal.holdCancelSuccess', {
                title: titleModel.get('title'),
            }),
            callback: () => window.location.reload(),
        };
        new ConfirmationModal(modalOptions);
    }

    holdSuspendSuccess(data) {
        const modalOptions = {
            copyToUse: window.text('confirmationModal.holdSuspendedSuccess', {
                title: data.title,
            }),
            triggerElement: data.triggerElement,
        };
        new ConfirmationModal(modalOptions);
    }

    holdEmailChangeSuccess(data) {
        const modalOptions = {
            copyToUse: window.text('confirmationModal.holdEmailChangeSuccess'),
            triggerElement: data.triggerElement,
        };
        new ConfirmationModal(modalOptions);
    }

    holdSuccess(data) {
        window.ajax.getHold(data.titleModel.get('id')).done((holdData) => {
            data.titleModel.set({
                holdsRatio: holdData.holdsRatio,
                holdsCount: holdData.holdsCount,
                holdListPosition: holdData.holdListPosition,
            });
            new HoldSuccessModal({
                bus: this.bus,
                titleModel: data.titleModel,
                email: window.card.preferredHoldEmail,
                autoCheckoutEnabled:
                    window.patronSettings['auto-checkout-setting'] === 1,
            });
        });
    }

    holdFail({ jqXHR, titleModel }) {
        const response = JSON.parse(jqXHR.responseText);

        if (response.errorCode === 'PatronCardFailedAuthentication') {
            this.bus.trigger('user:card:invalid');
        } else if (response.sessionExpired) {
            this.bus.trigger('user:session:expired', {
                action: `hold~${titleModel.id}`,
            });
        } else if (
            response.errorCode === 'HoldAlreadyExists' ||
            response.errorCode === 'PatronExceededHoldLimit'
        ) {
            let link = `<a class="bold" href="${window.url(
                window.routes.accountHolds
            )}" tabindex="0">${window.html('holds')}</a>`;
            this._errorToast(link, response.errorCode);
        } else if (
            response.errorCode === 'PatronTypeRestrictedFromTitle' ||
            response.errorCode === 'PatronHasHoldsDisabled'
        ) {
            let link = `<a class="bold" href="${window.url(
                window.routes.supportMembers
            )}" tabindex="0">${window.html('pleaseContactSupport')}</a>`;
            this._errorToast(link, response.errorCode);
        } else if (response.errorCode === 'HoldNotAllowedForVisitor') {
            new VisitorActionDisallowedModal('hold');
        } else if (response.errorCode) {
            this._errorToast('', response.errorCode);
        } else if (toaster) {
            toaster.push(
                `<strong>${window.html('genericError')}</strong>`,
                'error'
            );
        }
    }

    holdCancelFail({ jqXHR, textStatus, errorThrown, titleModel, context }) {
        let response = JSON.parse(jqXHR.responseText);
        if (response.sessionExpired) {
            this.bus.trigger('user:session:expired');
        } else if (toaster) {
            toaster.push(
                `<strong>${window.html('genericError')}</strong> ${window.html(
                    'error.' + response.errorCode
                )}`,
                'error'
            );
        }
    }

    _errorToast(pageLink, errorCode) {
        if (toaster)
            toaster.push(
                `<strong>${window.html('genericError')}</strong> ${window.text(
                    `error.${errorCode}`,
                    { link: pageLink }
                )}`,
                'error'
            );
    }

    _openHoldModal({ titleModel, context }) {
        if (window.featureManager.isEnabled('LHTNG-3387')) {
            if (
                window.OverDrive.isVisitor &&
                !this.featureManager.isEnabled('content-holds')
            ) {
                new VisitorActionDisallowedModal('hold');
            } else {
                new HoldModal({
                    bus: this.bus,
                    titleModel,
                    context,
                });
            }
        } else {
            new HoldModal({ bus: this.bus, titleModel, context });
        }
    }
}
