import { Modal } from '../../common/modal';
import PlaceHoldPanel from './PlaceHoldPanel';

export default class HoldModal extends Backbone.View {
    constructor({ bus, titleModel, context }) {
        super(...arguments);
        this.bus = bus;

        this.placeHoldPanel = new PlaceHoldPanel({
            bus,
            titleModel,
            context,
            isRequestAgain: !!context.isRequestAgain,
        });

        this.modal = new Modal({
            autoShow: false,
            title: window.html('dynamicHoldModal.placeAHold'),
            classes: ['small', 'HoldModal'],
            content: this.placeHoldPanel.$el,
            firstFocus: this.placeHoldPanel.$el.find('.js-first-focus'),
        });

        this.modal.show();

        this.listenTo(this.bus, 'title:hold:end', () => {
            this.modal.close();
        });
    }
}
