import keys from '../../util/keycodes';
import ModalTemplate from './templates/modal.html';

class Modal extends Backbone.View {
    get defaults() {
        // When the flag is enabled, closeOnEsc must always be FALSE
        // to ensure that the ESC key behavior defined in _bindEvents() executes properly.
        // Do not modify this value when calling a new modal.
        var closeModalOnEsc = window.featureManager.isEnabled(
            'close-dropdowns-on-esc'
        )
            ? false
            : true;

        return {
            autoShow: true,
            autoRender: true,
            closeOnEsc: closeModalOnEsc,
            closeOnBlur: true,

            id: '',
            classes: ['small'],
            buttonAlign: 'right',
            title: 'Title',
            hiddenTitle: false,
            content: 'Some Content',
            submitText: html('ok'),
            cancelText: html('cancel'),
            attachToDocument: true,
            defaultButtonBehaviour: true,
            showCloseButton: true,
        };
    }

    initialize(options) {
        _.extend(this, this.defaults, options);

        this.tpl = ModalTemplate;
        this.setElement(this.tpl(this));

        if (this.id.length) {
            this.$el.attr('id', this.id);
        }

        this.$content = this.$el.find('.modal-container');

        this.$content.empty().append(this.content);

        this.$el.addClass(this.classes.join(' '));

        this.$title = this.$el.find('.modal-title');

        if (this.hiddenTitle) {
            this.$title.css('display', 'none');
            this.$title.attr('aria-hidden', 'true');
        }

        if (this.autoRender) {
            this._render();
        }

        this._bindEvents();
    }

    _render() {
        if (this.attachToDocument) {
            // For Foundation to work as expected we need to have the element attached to the DOM before calling .foundation('reveal', 'open')
            $('body').append(this.el);
        }

        if (this.autoShow) {
            this.show();
        }

        return this;
    }

    isSelectEvent = (e) =>
        (e.type === 'click' || e.which === 13 || e.which === 32) &&
        (e.preventDefault() || true);

    // We dont actually want to use the Foundation modal events since they get weird fast...
    // lets use our own so consumers dont have to know its foundation
    _bindEvents() {
        this.$el.on('click keydown', '.js-close', (e) => {
            this.isSelectEvent(e) && this.trigger('cancel', this);
        });

        if (this.defaultButtonBehaviour) {
            this.on('close cancel', () => {
                this.close();
            });
        }

        this.$el.on('keypress', '*', (e) => {
            if (e.which === keys.TAB) {
                this._keepFocus(e);
            }
        });

        if (window.featureManager.isEnabled('close-dropdowns-on-esc')) {
            // Prevent ESC from closing modal when <select> elements are open
            this.$el.on('click keydown keyup', 'select', function (e) {
                var correctEventType =
                    e.type === 'click' ||
                    e.which === keys.ENTER ||
                    e.which === keys.ESC;
                if (!correctEventType) return;

                var selectOpen = $(this).attr('data-open');

                if (selectOpen === 'true') {
                    $(this).attr('data-open', 'false');
                    e.stopPropagation();
                }

                if (
                    (selectOpen === 'false' || selectOpen == null) &&
                    e.which !== keys.ESC
                ) {
                    $(this).attr('data-open', 'true');
                }
            });

            this.$el.on('blur', 'select', function () {
                $(this).attr('data-open', 'false');
            });

            // Close modals with ESC
            this.$el.on('keyup', (e) => {
                var selectIsNotOpen =
                    !$('select').is(':focus') ||
                    $(':focus').attr('data-open') !== 'true';
                if (
                    e.which === keys.ESC &&
                    selectIsNotOpen &&
                    $('.f-open-dropdown').length === 0
                ) {
                    this.trigger('cancel', this);
                }
            });
        }
    }

    show() {
        this._beforeShow();
        this.$el.foundation('reveal', 'open');
        this.trigger('show', this);

        this.$el.on('keydown', (e) => {
            this._keepFocus(e);
        });

        // Execute code after the modal is displayed
        this.$el.on('opened.fndtn.reveal', () => this._afterShow());

        // Traps foundation bug that closes modal on dropdown "closed", not an issue in 5.5.3+
        this.$el.off('closed');

        // Handle foundation  close events from ESC and BG clicks
        this.$el.on('close.fndtn.reveal', () => {
            this.$el.off('close.fndtn.reveal');
            this.trigger('close');
        });
    }

    _beforeShow() {
        this.focused = $(document.activeElement);
    }

    _afterShow() {
        this.$el.attr('aria-hidden', false);
        $('.container').attr('aria-hidden', 'true');
        $('.row.footer').attr('aria-hidden', 'true');
        $('.nav-container').attr('aria-hidden', 'true');

        this.$el.find(':focusable:visible').first().focus();
        this._setScroll();
    }

    _setScroll() {
        let offset =
            window.pageYOffset - parseInt(this.$el.css('top').slice(0, -2), 10); // the 'top' styling is baked in by foundation
        if (window.innerHeight < this.el.offsetHeight - offset) {
            // scroll tall modals into view as much as fits
            offset = Math.min(
                this.el.offsetHeight - window.innerHeight + 10,
                -5
            );
        } else if (window.innerWidth < 641) {
            // mobile view padding
            offset -= 5;
        }
        this.el.scrollIntoView(); // sets scroll to top of modal
        window.scrollBy(0, offset); // adjusts scroll according to calculated offset
    }

    _keepFocus(e) {
        const activeElem = $(document.activeElement);
        const firstFocusable = this.$el.find(':focusable:visible').first();
        const lastFocusable = this.$el.find(':focusable:visible').last();

        if (
            activeElem.is(firstFocusable) &&
            e.which === keys.TAB &&
            e.shiftKey
        ) {
            e.preventDefault();
            lastFocusable.focus();
        } else if (
            $(activeElem).is(lastFocusable) &&
            e.which === keys.TAB &&
            !e.shiftKey
        ) {
            e.preventDefault();
            firstFocusable.focus();
        }
    }

    hide() {
        this.$el.foundation('reveal', 'close');
        this.trigger('hide', this);
        $('.container').attr('aria-hidden', 'false');
        $('.row.footer').attr('aria-hidden', 'false');
        $('.nav-container').attr('aria-hidden', 'false');
        this.focused.focus();
    }

    close() {
        this.hide();
        this.remove();
    }
}

export { Modal };
