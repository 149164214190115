const { html } = window;

export default ({ bnLogoUrl }) => `
<div class="row">
    <div class="large-12 columns">
        <img src="${bnLogoUrl}" class="barnes-and-noble-logo">
    </div>
</div>

<div class="row">
    <div class="large-12 columns no-padding-left">
        <p tabindex="0">
            ${html('nookEULAModal.textBody')}<br/><br/>

            ${html('nookEULAModal.freeNookAppExplanationHtml', {iosLink: '<a href="https://itunes.apple.com/us/app/NOOK/id373582546" target="_blank" rel="noreferrer" class="modal-link">iOS</a>',
                androidLink: '<a href="https://play.google.com/store/apps/details?id=bn.ereader&hl=en" target="_blank" rel="noreferrer" class="modal-link">Android</a>',
                windowsLink: '<a href="https://www.microsoft.com/en-us/store/apps/nook-books-magazines-newspapers-comics/9wzdncrfj33h" target="_blank" rel="noreferrer" class="modal-link">Windows 8</a>'})}
        </p>
    </div>
</div>
`;
