export default ({ media, src, href, coverColor, noSampleClass, srcset }) => `
<div class="CoverImageContainer" style="background-color: ${coverColor}">
    <a href="${href}"
           data-href    ="${href}"
           data-media-id="${media.id}"
           data-title   ="${media.title}"
           data-type    ="${media.type.id}">
        <img
            class="large-title js-details-cover-image ${noSampleClass} "
            src="${src}"
            ${srcset && `srcset="${srcset}"`}
            alt="${media.title} - ${html(media.type.id)}"
            style="background-color: ${coverColor}; max-height: none !important;"/>
    </a>
</div>`;
