import AutoScroll from '../auto_scroll/autoScroll';
import BackButtonRedirect from '../back_button_redirect/BackButtonRedirect';
import SimpleHash from '../../../../lib/simpleHash';

export default class HistoryCookieTracker {
    constructor() {
        this.pageUnloaded = false;
        this.MAX_LENGTH = 3;
        this._initCookieQueue([
            new AutoScroll(this),
            new BackButtonRedirect(this),
        ]);
    }

    _initCookieQueue(listeners) {
        let previousStateString = Cookies.get('urlHistory');
        let states = previousStateString ? JSON.parse(previousStateString) : [];
        while (states.length > this.MAX_LENGTH - 1) {
            states.shift();
        }
        const pathname = window.location.pathname
            ? window.location.pathname
            : '/';
        const search = window.location.search ? window.location.search : '';
        const hash = window.location.hash ? window.location.hash : '';
        const route = pathname + search + hash;
        states.push(JSON.stringify({ route: SimpleHash.hash(route) }));
        Cookies.set('urlHistory', states, {
            path: '/',
            sameSite: 'lax',
            secure: true,
        });
        this._addEventListeners(listeners, states);
    }

    wasBackButtonHit() {
        let states = this.getData();
        if (states.length < 3) {
            return false;
        }
        const antePenultimateRoute = states[states.length - 3].route;
        const penultimateRoute = states[states.length - 2].route;
        const ultimateRoute = states[states.length - 1].route;
        return (
            states.length >= 3 &&
            antePenultimateRoute === ultimateRoute &&
            ultimateRoute !== penultimateRoute
        );
    }

    addDataToLatest(name, value) {
        let previousStateString = Cookies.get('urlHistory');
        if (previousStateString !== undefined) {
            let states = JSON.parse(previousStateString);
            // sometimes a string sometimes an object but needs to be an object every time
            let state =
                typeof states[states.length - 1] === 'string'
                    ? JSON.parse(states[states.length - 1])
                    : states[states.length - 1];
            state[name] = value;
            states[states.length - 1] = state;
            Cookies.set('urlHistory', JSON.stringify(states), {
                path: '/',
                sameSite: 'lax',
                secure: true,
            });
        }
    }

    getData(index) {
        if (Cookies.get('urlHistory')) {
            let previousStateString = Cookies.get('urlHistory');
            let states = JSON.parse(previousStateString);
            for (let i = 0; i < states.length; i++) {
                states[i] =
                    typeof states[i] === 'string'
                        ? JSON.parse(states[i])
                        : states[i];
            }
            if (index > -1 && states[index]) {
                return states[index];
            } else if (!index) {
                return states;
            }
        }
        return [];
    }

    _addEventListeners(listeners, states) {
        for (let listener of listeners) {
            listener.onLoad(states);
            // For browsers that support beforeunload
            window.addEventListener(
                'beforeunload',
                () => {
                    if (!this.pageUnloaded) {
                        listener.onUnload();
                        this.pageUnloaded = true;
                    }
                },
                false
            );

            // For Safari
            window.addEventListener(
                'pagehide',
                () => {
                    if (!this.pageUnloaded) {
                        listener.onUnload();
                        this.pageUnloaded = true;
                    }
                },
                false
            );
        }
    }
}
