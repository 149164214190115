import BaseView from '../../../views/base';
import keycodes from '../../../util/keycodes';

const _ = window._;

const SearchBar = BaseView.extend({
    events: {
        'click .js-doQuickSearch': 'submitForm',
        'keyup .js-quickSearchInput': 'keyup',
        'change .js-quickSearchInput': 'change',
        'input .js-quickSearchInput': 'input',
        'click .js-clearSearch': 'clearSearch',
    },

    initialize({ el, query, options }) {
        this._injectCustomFunctioanlity();

        this.setElement(el);
        this.delegateEvents();
        this.query = query;

        this.$input = this.$el.find('.js-quickSearchInput');
        this.$form = this.$el.find('.js-quickSearchForm');
        this.$toggleSearch = this.$el.find('.js-toggleSearch');
        this.$clearSearch = this.$el.find('.js-clearSearch');

        // LHTNG-1215 - Sync any input provided staticaly from the form markup
        this.query.set('text', this.$input.val());

        _.extend(this, options || {});

        if (this.$toggleSearch) {
            this.$toggleSearch.on('click keypress', (ev) => {
                this.toggleSearch(ev);
            });
        }
    },

    _injectCustomFunctioanlity() {
        $.fn.setCursorToTextEnd = function () {
            const $initialVal = this.val();
            this.val('').val($initialVal);
        };
    },

    submitForm(ev) {
        if (this.query.isValidForSearch()) {
            this.$form.submit();
        } else {
            ev.stopPropagation();
            ev.preventDefault();
        }
    },

    keyup(ev) {
        this.toggleClearIcon();

        if (this.keyupMap.has(ev.which)) {
            this.keyupMap.get(ev.which)(this);
        } else {
            this.query.set('text', ev.target.value);
            ev.stopPropagation();
            ev.preventDefault();
        }
    },

    input(ev) {
        this.toggleClearIcon();
        this.query.set('text', ev.target.value);
        ev.stopPropagation();
        ev.preventDefault();
    },

    toggleClearIcon() {
        if (this.$input.val()) {
            this.$clearSearch.show();
        } else {
            this.$clearSearch.hide();
        }
    },

    clearSearch() {
        this.$input.val('').focus();
        this.$clearSearch.hide();
    },

    keyupMap: new Map([[keycodes.ENTER, (self) => self.submitForm()]]),

    toggleSearch(ev) {
        if (
            ev.which === keycodes.SPACE ||
            ev.which === keycodes.ENTER ||
            ev.type === 'click'
        ) {
            this.$el.toggleClass('hideMe');
            this.$input.focus().setCursorToTextEnd();
        }

        this.toggleClearIcon();

        if (toaster) toaster._stickToast();
    },
});

export default SearchBar;
