
const { html, text } = window;

export default ({successMessageTextKey, manageHoldsUrl, email, titleModel, creatorSearchUrl, holdsInfo, ownedCopies}) =>  `
    <h4 class="HoldSucessModal-mediaTitle">${titleModel.title}</h4>
    <div class="HoldSuccessModal-author" >
        ${text('byLine', {creator: `<a class="HoldSuccessModal-link" href="${creatorSearchUrl}" aria-label="${html('cover.searchByAuthor', {author: titleModel.firstCreatorName})}">${titleModel.firstCreatorName}</a>`})}
    </div>
    <div class="HoldSuccessModal-holdInfo">
        ${holdsInfo}
    </div>
    <div class="HoldSuccessModal-successMessage">
        ${ ownedCopies !== 0
            ? html(successMessageTextKey, {email: email, manageHoldsLink: `<a class="HoldSuccessModal-link" href="${manageHoldsUrl}">${window.html('holdSuccessModal.manageHolds')}</a>`}) 
            : ``}
    </div>
    <div class="relatedTitlesContainer">
        <div class="loader"></div>
    </div>`;
