import svgLoader from '../../util/svgLoader';
import keys from '../../util/keycodes';
const { text, url } = window;

export default class NotificationDrawer extends Backbone.View {
    constructor() {
        super(...arguments);
        this.$triggerEl = $('.js-notificationsButton');
        this.isOpen = false;
        this.bus = window.globalEvents;

        this.renderShell();
        this.bus.on('title:borrow:success', () =>
            setTimeout(() => this.loadSimplifiedNotifications(), 500)
        );
        this.loadSimplifiedNotifications();

        this.$el.on('click', (e) => {
            // clicks inside the container are trapped so they can't trigger the close handler.
            e.stopPropagation();
        });

        $('body').on('click', (e) => this.tryClose(e));
        this.$triggerEl.on('click', (e) => this.toggle(e));

        this.$el.on('keyup', (e) => {
            if (e.which === keys.ESC) {
                this.tryClose(e);
            }
        });
    }

    close() {
        this.$el.detach();
        this.isOpen = false;
    }

    tryClose(e) {
        if (this.isOpen && e && !e.originalEvent.isDrawerOpenClick) {
            this.close();
        }
    }

    open(e) {
        if (!this.isOpen) {
            e.originalEvent.isDrawerOpenClick = true;
            // There are two potential click targets: one for the mobile nav and one for the desktop nav
            $(e.currentTarget)
                .siblings('.Notifications-Dropdown')
                .append(this.$el);
            this.isOpen = true;
            $('.icon-notification').removeClass('ring');
            svgLoader();

            this.$el.find(':focusable:visible').first().focus();

            this.$el.on('keydown', (ev) => {
                if (ev.which === keys.TAB) {
                    this.keepFocus(ev);
                }
            });
        }
    }

    toggle(e) {
        if (this.isOpen) {
            this.tryClose(e);
            e.stopPropagation();
        } else {
            this.open(e);
        }
    }

    keepFocus(e) {
        const activeElem = $(document.activeElement);
        const firstFocusable =
            this.$('.Notification-item')[0] ||
            this.$el.find(':focusable:visible').first();
        const lastFocusable = this.$el.find(':focusable:visible:tabbable')[
            this.$el.find(':focusable:visible:tabbable').length - 1
        ];

        if (
            activeElem.is(firstFocusable) &&
            e.which === keys.TAB &&
            e.shiftKey
        ) {
            e.preventDefault();
            lastFocusable.focus();
        } else if (
            $(activeElem).is(lastFocusable) &&
            e.which === keys.TAB &&
            !e.shiftKey
        ) {
            e.preventDefault();
            firstFocusable.focus();
        }
    }

    startSpinner(target) {
        target.append(this.$spinner);
    }

    stopSpinner() {
        this.$spinner.detach();
    }

    startNagging(resultCount) {
        $('.notificationDotContainer').css('display', 'block');
        $('.notificationDotContainer.mobile').css('display', 'inline-block');
        $('.js-notificationsButton').attr('role', 'button alert');
        $('.icon-notification').addClass('ring');
        $('.js-notificationsButton').attr(
            'aria-label',
            text('notifications.activeNotificationsLabel', {
                num: resultCount,
            })
        );
    }

    clearNotificationIcon() {
        $('.notificationDotContainer').css('display', 'none');
        $('.notificationDotContainer.mobile').css('display', 'none');
        $('.js-notificationsButton').removeAttr('role');
        $('.icon-notification').removeClass('ring');
        $('.js-notificationsButton').attr(
            'aria-label',
            text('notifications.notificationsLabel')
        );
    }

    renderShell() {
        this.$el.empty();
        this.$el.append(`<div class='NotificationsCaret white'></div>
                        <div class="NotificationsContainer">
                            <div class="spinnerBox">
                                <div class='SpinnerContainer'>
                                    <div class='spinner'></div>
                                </div>
                            </div>
                        </div>`);
        this.$spinner = this.$el.find('.spinnerBox');
        this.$mainContainer = this.$el.find('.NotificationsContainer');
        this.$caret = this.$el.find('.NotificationsCaret');
    }

    loadSimplifiedNotifications() {
        this.getHolds()
            .then((response) => {
                this.stopSpinner();
                this.renderSimplifiedNotifications(response.holdsAvailable);
            })
            .catch((error) => {
                this.stopSpinner();
                window.Logger.log('error', 'Failed to show notifications', {
                    message: error.statusText || error,
                });
                this.$mainContainer.empty().append(
                    $(`<div class='Simplified-No-Notifications'>
                                <div class='Simplified-No-NotificationTextContainer'>
                                    <p>${text('notifications.fetchingError', {
                                        link: this.getContactSupportLink(),
                                    })}</p>
                                </div>
                        </div>`)
                );
            });
    }

    getHolds() {
        return new Promise((resolve, reject) =>
            $.ajax({
                method: 'GET',
                url: window.url(window.routes.rest.holds.available),
                timeout: 10000,
            })
                .done((response) => {
                    resolve(response);
                })
                .fail((xhr, status, err) => reject(err))
        );
    }

    renderSimplifiedNotifications(holdsAvailable) {
        if (holdsAvailable) {
            this.holdsReadyMessage();
            this.startNagging(1);
        } else {
            this.simplifiedNoNotificationsMessage();
            this.clearNotificationIcon();
        }
    }

    simplifiedNoNotificationsMessage() {
        this.$mainContainer.empty()
            .append(`<div class='Simplified-No-Notifications' tabindex='0'>
                        <div class='Simplified-No-NotificationImageContainer svg dynamic-loader' data-src='notifications-empty.svg'></div>
                        <div class='Simplified-No-NotificationTextContainer'>
                            <h5 class='Simplified-No-Notifications-Header'>${text(
                                'notifications.noNotifications.header'
                            )}</h5>
                            <p>${text(
                                'notifications.noNotifications.simplifiedMainText'
                            )}</p>
                        </div>
                        </div>`);
    }

    holdsReadyMessage() {
        const holdsLink = url(window.routes.accountHolds);
        this.$mainContainer.empty()
            .append(`<div class='Hold-Ready-Notification' tabindex='0'>
                        <h5 class='Hold-Ready-Notifications-Header'>${text(
                            'notifications.simplifiedHoldReady.header'
                        )}</h5>
                        <p>${text(
                            'notifications.simplifiedHoldReady.mainText',
                            {
                                link: `<a href=${holdsLink}>${text(
                                    'holds'
                                )}</a>`,
                            }
                        )}</p>
                    </div>`);
    }

    getContactSupportLink() {
        let link = '';
        if (window.OverDrive.librarySupportUrl !== '') {
            link = window.OverDrive.librarySupportUrl;
        } else if (window.OverDrive.librarySupportEmail !== '') {
            link = window.OverDrive.librarySupportEmail;
        } else {
            link = url(window.routes.supportMembers);
        }
        return `<a class='primary-color' href='${link}' target='_blank' rel='noreferrer'>${text(
            'contactSupport'
        ).toLowerCase()}</a>`;
    }
}
